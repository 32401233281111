import { NgModule } from '@angular/core';
import { QuicklinkModule, QuicklinkStrategy } from 'ngx-quicklink';
import { Routes, RouterModule } from '@angular/router';
import { QuickRegister } from './route-guards/quick-register.guard';

const routes: Routes = [
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then((m) => m.LoginModule),
    canActivate: [QuickRegister],
  },
  { path: 'teachers', loadChildren: () => import('./pages/home/home.module').then((m) => m.HomeModule) },
  { path: '**', redirectTo: 'login' },
];

@NgModule({
  imports: [
    QuicklinkModule,
    RouterModule.forRoot(routes, { preloadingStrategy: QuicklinkStrategy, relativeLinkResolution: 'legacy' }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
