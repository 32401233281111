import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable()
export class QuickRegister implements CanActivate {
  constructor(private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const isQuickRegister = route.queryParamMap.has('quick-register');
    if (isQuickRegister) {
      return this.router.navigate(['/quick-register'], {
        queryParams: {
          institutionId: route.queryParamMap.get('institutionId'),
          courseId: route.queryParamMap.get('courseId'),
        },
      });
    }
    return true;
  }
}
