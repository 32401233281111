import { firebaseConfig } from '../../../../src/environments/firebase.config';
import { bugsnagConfig } from '../../../../src/environments/bugsnag.config';

export const environment = {
  production: true,
  revealPresentation: 'https://rollscroll-presentations.web.app',
  firebaseConfig,
  bugsnagConfig: {
    ...bugsnagConfig,
    stage: 'production',
    appType: 'Teacher',
  },
};
